import { app } from '@simpl/core/init-app'
import { Store } from 'vuex'
import storeAdapterStore from './store'
import { IAdapterCMS } from '@simpl/cms/types'
import { ACTIONS, MUTATIONS } from '@simpl/cms-components/adapters/StoreAdapter/store/consts'

export default class StoreAdapter implements IAdapterCMS {
  store: Store<any>
  runId: string | number | null
  moduleId: string | number | null
  currentObjective: string | number | null

  constructor () {
    this.store = app.$store
    this.moduleId = null
    this.runId = null
    this.currentObjective = null
  }

  async init (runId: string | number | null, moduleId: string | number | null) {
    this.runId = runId
    this.moduleId = moduleId

    if (!this.store.state.storeAdapter) {
      await this.store.registerModule('storeAdapter', storeAdapterStore)
    }
  }

  read<T extends any> (key: string, defaultValue?: T): T {
    return this.store.getters['module/getTracking'](this.runId, this.moduleId, this.currentObjective, key) || defaultValue
  }

  write (key: string, data: any, score?:number): void {
    this.store.dispatch(
      `module/${ACTIONS.UPDATE_TRACKING_DATA}`,
      {
        data: {
          objective: this.currentObjective,
          key: key,
          data: data,
          score: score || null
        },
        runId: this.runId,
        moduleId: this.moduleId
      }
    )

    this.store.commit(`storeAdapter/${MUTATIONS.SET_TRACKING_DATA}`, {
      objective: this.currentObjective, key, data
    })
  }

  completionStatus (): number {
    return this.store.getters['storeAdapter/completionStatus']
  }

  setCurrentObjective (objective: string | number) {
    this.currentObjective = objective
  }

  getCurrentObjective (): string | number | null {
    return this.currentObjective
  }

  isObjectiveCompleted (objective: string | number): boolean {
    return this.store.getters['storeAdapter/isObjectiveCompleted'](objective)
  }

  setCompleted (objective: string | number): void {
    this.store.commit(`storeAdapter/${MUTATIONS.SET_COMPLETED}`, objective)
  }
}
